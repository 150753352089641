import { render, staticRenderFns } from "./ConfelenceView.vue?vue&type=template&id=f216fcd8&scoped=true&"
import script from "./ConfelenceView.vue?vue&type=script&lang=js&"
export * from "./ConfelenceView.vue?vue&type=script&lang=js&"
import style0 from "./ConfelenceView.vue?vue&type=style&index=0&id=f216fcd8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f216fcd8",
  null
  
)

export default component.exports